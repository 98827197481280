<template>
    <input type="text" class="form-control">
    <div class="container-fluid">
        <section class="content-header">
            <div class="row mb-2">
                <h3>{{ $t('general.listadoestadospendientes') }}</h3>
            </div>
        </section>
        <section class="content">
            <div class="row">
                <div class="col-md-2">
                </div>
                <div class="col-md-10">
                    <tabla-datos :columnas="columnas" :datos="chatsFiltrados" :filtros="false"
                        :total="total" @quieroDatos="obtenerEstadosPendientes" :paginator=false @cambianombre="cambianombre">
                    </tabla-datos>
                </div>
            </div>
        </section>
    </div>
    
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
import TablaDatos from '../../../TablaDatos.vue';

export default {
    components: {
        "tabla-datos": TablaDatos,
    },
    computed:{
        chatsFiltrados() {            
            let estados = this.estados_pendientes;
            console.log('estadosd', estados);
            if (this.nombrevalue) {
                console.log('nombrevalue', this.nombrevalue);
                estados = estados.filter(estado => estado.nombre.toLowerCase().includes(this.nombrevalue.toLowerCase()));
            }
            if(estados){
                return estados;
            }else{
                console.log('nada');
                return [];
            }
        }
    },
    data() {
        return {
            haynombre: false,
            nombrevalue:'',
            total: '',
            estados_pendientes: [],
            id_estado_pendiente: '',
            parametros: null, //query params de DataTable
            columnas: [
                {
                    field: 'nombre',
                    nombrefilt:'nombrefilt',
                    sortable: true,
                },
                {
                    header: 'Acciones',
                    data: null,
                    acciones: [
                        {
                            nombre: 'Editar',
                            class: 'editar',
                            tipo: 'default',
                            nombreId: 'id_estado',
                            icono: 'far fa-edit',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                
                                this.$router.push({
                                    name: 'Estado Pendiente',
                                    params: {
                                        id
                                    }
                                });
                                
                            }
                        },
                        {
                            nombre: 'Eliminar',
                            class: 'eliminar',
                            tipo: 'danger',
                            nombreId: 'id_estado',
                            icono: 'far fa-trash-alt',
                            // Debe ser arrow function para que this sea este componente
                            accion: (id) => {
                                console.log('Eliminar la especialidad pendiente ' + id);
                                if (confirm('Desea eliminar la especialidad pendiente')) {
                                    this.eliminar(id);
                                }
                            }
                        },
                    ]
                }
            ]
        }
    },
    //  modulos/pwgsapi/index.php/estadospendientes/:id/
    //  modulos/pwgsapi/index.php/estadospendientes/0/
    methods: {
        cambianombre(value){
            this.nombrevalue = value;
            console.log('cambiando nombre a ', this.nombrevalue);
        },
        async obtenerEstadosPendientes() {
            const api = new PwgsApi;
            const aux = await api.get('tipos-estado-pendiente');
            this.estados_pendientes = aux.datos.estados_pendientes;
            console.log('estadospend', this.estados_pendientes);
            this.total = this.estados_pendientes.length;
            this.columnas[1].header = this.$t("general.acciones");
        },
        async eliminar(id) {
            console.log('eliminando estado pendiente ' + id + '...');
            const api = new PwgsApi;
            await api.delete('tipos-estado-pendiente/' + id);
            this.obtenerEstadosPendientes();
        },
    },
    mounted() {
        this.obtenerEstadosPendientes();
    }
}
</script>
<style>
.p-dialog {
    width: 90%;
}
</style>